<template>
  <v-menu
    v-if="message"
    v-model="menu"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
      >
        Resend
      </v-btn>
    </template>

    <v-card>
      <v-card-subtitle>{{ message.email }}</v-card-subtitle>
      <v-card-text>
        <v-text-field
          v-model="price"
          :rules="priceRules"
          label="Price*"
          required
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          @click="resend"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["message"],
  data: () => ({
    menu: false,
    price: "",
    priceRules: [
      (value) => !!value || "Required.",
      (value) => {
        const pattern = /^[0-9]+$/;
        return pattern.test(value) || "Invalid number.";
      },
    ],
  }),
  methods: {
     ...mapActions("inventory", ["sendWithDanAction"]),
    resend() {
      console.log("sending with DAN message", this.message);
      let newMessage = {...this.message};
      newMessage.price=this.price;
      this.sendWithDanAction(newMessage);
      this.menu = false;
    },
  },
  mounted(){
    this.price=this.message.price
  }
};
</script>

<style></style>
